import { default as aboutlAUAmFPn1JMeta } from "/opt/app/pages/about.vue?macro=true";
import { default as _91page_93mgaPke34jTMeta } from "/opt/app/pages/blog/[page].vue?macro=true";
import { default as indexEohrYP9OICMeta } from "/opt/app/pages/blog/index.vue?macro=true";
import { default as capitalsC8CPT5hCFMeta } from "/opt/app/pages/capital.vue?macro=true";
import { default as contactsPaPMo63Dd8Meta } from "/opt/app/pages/contacts.vue?macro=true";
import { default as documentsaoxiioVcZEMeta } from "/opt/app/pages/dashboard/documents.vue?macro=true";
import { default as index6ZqpsBs84HMeta } from "/opt/app/pages/dashboard/index.vue?macro=true";
import { default as portfolioVvvlYOxXKkMeta } from "/opt/app/pages/dashboard/portfolio.vue?macro=true";
import { default as profileaqFkneUoOvMeta } from "/opt/app/pages/dashboard/profile.vue?macro=true";
import { default as referralsxHABqqHLlJMeta } from "/opt/app/pages/dashboard/referrals.vue?macro=true";
import { default as founderuOyCfn6bu3Meta } from "/opt/app/pages/founder.vue?macro=true";
import { default as _91page_93nWZkQTaZJcMeta } from "/opt/app/pages/funds/[page].vue?macro=true";
import { default as index7zHUxHdrhvMeta } from "/opt/app/pages/funds/index.vue?macro=true";
import { default as handbookwS0CC6gK1LMeta } from "/opt/app/pages/handbook.vue?macro=true";
import { default as index1JQZ1qn7X7Meta } from "/opt/app/pages/index.vue?macro=true";
import { default as inzhur_45development9cMhFkcsqlMeta } from "/opt/app/pages/inzhur-development.vue?macro=true";
import { default as _91page_93G0REO17BHXMeta } from "/opt/app/pages/property/[page].vue?macro=true";
import { default as indexc3X2wruJFIMeta } from "/opt/app/pages/property/index.vue?macro=true";
import { default as referral_45program3zT5Xk5NJPMeta } from "/opt/app/pages/referral-program.vue?macro=true";
import { default as repoutDViFbfm0Meta } from "/opt/app/pages/repo.vue?macro=true";
import { default as reset_45passwordPbYjOO8NxGMeta } from "/opt/app/pages/reset-password.vue?macro=true";
import { default as indexKv1TOdiA6kMeta } from "/opt/app/pages/signin/index.vue?macro=true";
import { default as documentsL6gVwsjFezMeta } from "/opt/app/pages/signup/documents.vue?macro=true";
import { default as pendingGJKP5KHuOaMeta } from "/opt/app/pages/signup/pending.vue?macro=true";
import { default as indexE4tc8OKLyuMeta } from "/opt/app/pages/signup/questionnaire/index.vue?macro=true";
import { default as signingOEYeC0rwxdMeta } from "/opt/app/pages/signup/questionnaire/signing.vue?macro=true";
import { default as component_45stubzIDddqOzp9Meta } from "/opt/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubzIDddqOzp9 } from "/opt/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: aboutlAUAmFPn1JMeta?.name ?? "about___uk",
    path: aboutlAUAmFPn1JMeta?.path ?? "/about",
    meta: aboutlAUAmFPn1JMeta || {},
    alias: aboutlAUAmFPn1JMeta?.alias || [],
    redirect: aboutlAUAmFPn1JMeta?.redirect,
    component: () => import("/opt/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: _91page_93mgaPke34jTMeta?.name ?? "blog-page___uk",
    path: _91page_93mgaPke34jTMeta?.path ?? "/blog/:page()",
    meta: _91page_93mgaPke34jTMeta || {},
    alias: _91page_93mgaPke34jTMeta?.alias || [],
    redirect: _91page_93mgaPke34jTMeta?.redirect,
    component: () => import("/opt/app/pages/blog/[page].vue").then(m => m.default || m)
  },
  {
    name: indexEohrYP9OICMeta?.name ?? "blog___uk",
    path: indexEohrYP9OICMeta?.path ?? "/blog",
    meta: indexEohrYP9OICMeta || {},
    alias: indexEohrYP9OICMeta?.alias || [],
    redirect: indexEohrYP9OICMeta?.redirect,
    component: () => import("/opt/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: capitalsC8CPT5hCFMeta?.name ?? "capital___uk",
    path: capitalsC8CPT5hCFMeta?.path ?? "/capital",
    meta: capitalsC8CPT5hCFMeta || {},
    alias: capitalsC8CPT5hCFMeta?.alias || [],
    redirect: capitalsC8CPT5hCFMeta?.redirect,
    component: () => import("/opt/app/pages/capital.vue").then(m => m.default || m)
  },
  {
    name: contactsPaPMo63Dd8Meta?.name ?? "contacts___uk",
    path: contactsPaPMo63Dd8Meta?.path ?? "/contacts",
    meta: contactsPaPMo63Dd8Meta || {},
    alias: contactsPaPMo63Dd8Meta?.alias || [],
    redirect: contactsPaPMo63Dd8Meta?.redirect,
    component: () => import("/opt/app/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: documentsaoxiioVcZEMeta?.name ?? "dashboard-documents___uk",
    path: documentsaoxiioVcZEMeta?.path ?? "/dashboard/documents",
    meta: documentsaoxiioVcZEMeta || {},
    alias: documentsaoxiioVcZEMeta?.alias || [],
    redirect: documentsaoxiioVcZEMeta?.redirect,
    component: () => import("/opt/app/pages/dashboard/documents.vue").then(m => m.default || m)
  },
  {
    name: index6ZqpsBs84HMeta?.name ?? "dashboard___uk",
    path: index6ZqpsBs84HMeta?.path ?? "/dashboard",
    meta: index6ZqpsBs84HMeta || {},
    alias: index6ZqpsBs84HMeta?.alias || [],
    redirect: index6ZqpsBs84HMeta?.redirect,
    component: () => import("/opt/app/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: portfolioVvvlYOxXKkMeta?.name ?? "dashboard-portfolio___uk",
    path: portfolioVvvlYOxXKkMeta?.path ?? "/dashboard/portfolio",
    meta: portfolioVvvlYOxXKkMeta || {},
    alias: portfolioVvvlYOxXKkMeta?.alias || [],
    redirect: portfolioVvvlYOxXKkMeta?.redirect,
    component: () => import("/opt/app/pages/dashboard/portfolio.vue").then(m => m.default || m)
  },
  {
    name: profileaqFkneUoOvMeta?.name ?? "dashboard-profile___uk",
    path: profileaqFkneUoOvMeta?.path ?? "/dashboard/profile",
    meta: profileaqFkneUoOvMeta || {},
    alias: profileaqFkneUoOvMeta?.alias || [],
    redirect: profileaqFkneUoOvMeta?.redirect,
    component: () => import("/opt/app/pages/dashboard/profile.vue").then(m => m.default || m)
  },
  {
    name: referralsxHABqqHLlJMeta?.name ?? "dashboard-referrals___uk",
    path: referralsxHABqqHLlJMeta?.path ?? "/dashboard/referrals",
    meta: referralsxHABqqHLlJMeta || {},
    alias: referralsxHABqqHLlJMeta?.alias || [],
    redirect: referralsxHABqqHLlJMeta?.redirect,
    component: () => import("/opt/app/pages/dashboard/referrals.vue").then(m => m.default || m)
  },
  {
    name: founderuOyCfn6bu3Meta?.name ?? "founder___uk",
    path: founderuOyCfn6bu3Meta?.path ?? "/founder",
    meta: founderuOyCfn6bu3Meta || {},
    alias: founderuOyCfn6bu3Meta?.alias || [],
    redirect: founderuOyCfn6bu3Meta?.redirect,
    component: () => import("/opt/app/pages/founder.vue").then(m => m.default || m)
  },
  {
    name: _91page_93nWZkQTaZJcMeta?.name ?? "funds-page___uk",
    path: _91page_93nWZkQTaZJcMeta?.path ?? "/funds/:page()",
    meta: _91page_93nWZkQTaZJcMeta || {},
    alias: _91page_93nWZkQTaZJcMeta?.alias || [],
    redirect: _91page_93nWZkQTaZJcMeta?.redirect,
    component: () => import("/opt/app/pages/funds/[page].vue").then(m => m.default || m)
  },
  {
    name: index7zHUxHdrhvMeta?.name ?? "funds___uk",
    path: index7zHUxHdrhvMeta?.path ?? "/funds",
    meta: index7zHUxHdrhvMeta || {},
    alias: index7zHUxHdrhvMeta?.alias || [],
    redirect: index7zHUxHdrhvMeta?.redirect,
    component: () => import("/opt/app/pages/funds/index.vue").then(m => m.default || m)
  },
  {
    name: handbookwS0CC6gK1LMeta?.name ?? "handbook___uk",
    path: handbookwS0CC6gK1LMeta?.path ?? "/handbook",
    meta: handbookwS0CC6gK1LMeta || {},
    alias: handbookwS0CC6gK1LMeta?.alias || [],
    redirect: handbookwS0CC6gK1LMeta?.redirect,
    component: () => import("/opt/app/pages/handbook.vue").then(m => m.default || m)
  },
  {
    name: index1JQZ1qn7X7Meta?.name ?? "index___uk",
    path: index1JQZ1qn7X7Meta?.path ?? "/",
    meta: index1JQZ1qn7X7Meta || {},
    alias: index1JQZ1qn7X7Meta?.alias || [],
    redirect: index1JQZ1qn7X7Meta?.redirect,
    component: () => import("/opt/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: inzhur_45development9cMhFkcsqlMeta?.name ?? "inzhur-development___uk",
    path: inzhur_45development9cMhFkcsqlMeta?.path ?? "/inzhur-development",
    meta: inzhur_45development9cMhFkcsqlMeta || {},
    alias: inzhur_45development9cMhFkcsqlMeta?.alias || [],
    redirect: inzhur_45development9cMhFkcsqlMeta?.redirect,
    component: () => import("/opt/app/pages/inzhur-development.vue").then(m => m.default || m)
  },
  {
    name: _91page_93G0REO17BHXMeta?.name ?? "property-page___uk",
    path: _91page_93G0REO17BHXMeta?.path ?? "/property/:page()",
    meta: _91page_93G0REO17BHXMeta || {},
    alias: _91page_93G0REO17BHXMeta?.alias || [],
    redirect: _91page_93G0REO17BHXMeta?.redirect,
    component: () => import("/opt/app/pages/property/[page].vue").then(m => m.default || m)
  },
  {
    name: indexc3X2wruJFIMeta?.name ?? "property___uk",
    path: indexc3X2wruJFIMeta?.path ?? "/property",
    meta: indexc3X2wruJFIMeta || {},
    alias: indexc3X2wruJFIMeta?.alias || [],
    redirect: indexc3X2wruJFIMeta?.redirect,
    component: () => import("/opt/app/pages/property/index.vue").then(m => m.default || m)
  },
  {
    name: referral_45program3zT5Xk5NJPMeta?.name ?? "referral-program___uk",
    path: referral_45program3zT5Xk5NJPMeta?.path ?? "/referral-program",
    meta: referral_45program3zT5Xk5NJPMeta || {},
    alias: referral_45program3zT5Xk5NJPMeta?.alias || [],
    redirect: referral_45program3zT5Xk5NJPMeta?.redirect,
    component: () => import("/opt/app/pages/referral-program.vue").then(m => m.default || m)
  },
  {
    name: repoutDViFbfm0Meta?.name ?? "repo___uk",
    path: repoutDViFbfm0Meta?.path ?? "/repo",
    meta: repoutDViFbfm0Meta || {},
    alias: repoutDViFbfm0Meta?.alias || [],
    redirect: repoutDViFbfm0Meta?.redirect,
    component: () => import("/opt/app/pages/repo.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordPbYjOO8NxGMeta?.name ?? "reset-password___uk",
    path: reset_45passwordPbYjOO8NxGMeta?.path ?? "/reset-password",
    meta: reset_45passwordPbYjOO8NxGMeta || {},
    alias: reset_45passwordPbYjOO8NxGMeta?.alias || [],
    redirect: reset_45passwordPbYjOO8NxGMeta?.redirect,
    component: () => import("/opt/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: indexKv1TOdiA6kMeta?.name ?? "signin___uk",
    path: indexKv1TOdiA6kMeta?.path ?? "/signin",
    meta: indexKv1TOdiA6kMeta || {},
    alias: indexKv1TOdiA6kMeta?.alias || [],
    redirect: indexKv1TOdiA6kMeta?.redirect,
    component: () => import("/opt/app/pages/signin/index.vue").then(m => m.default || m)
  },
  {
    name: documentsL6gVwsjFezMeta?.name ?? "signup-documents___uk",
    path: documentsL6gVwsjFezMeta?.path ?? "/signup/documents",
    meta: documentsL6gVwsjFezMeta || {},
    alias: documentsL6gVwsjFezMeta?.alias || [],
    redirect: documentsL6gVwsjFezMeta?.redirect,
    component: () => import("/opt/app/pages/signup/documents.vue").then(m => m.default || m)
  },
  {
    name: pendingGJKP5KHuOaMeta?.name ?? "signup-pending___uk",
    path: pendingGJKP5KHuOaMeta?.path ?? "/signup/pending",
    meta: pendingGJKP5KHuOaMeta || {},
    alias: pendingGJKP5KHuOaMeta?.alias || [],
    redirect: pendingGJKP5KHuOaMeta?.redirect,
    component: () => import("/opt/app/pages/signup/pending.vue").then(m => m.default || m)
  },
  {
    name: indexE4tc8OKLyuMeta?.name ?? "signup-questionnaire___uk",
    path: indexE4tc8OKLyuMeta?.path ?? "/signup/questionnaire",
    meta: indexE4tc8OKLyuMeta || {},
    alias: indexE4tc8OKLyuMeta?.alias || [],
    redirect: indexE4tc8OKLyuMeta?.redirect,
    component: () => import("/opt/app/pages/signup/questionnaire/index.vue").then(m => m.default || m)
  },
  {
    name: signingOEYeC0rwxdMeta?.name ?? "signup-questionnaire-signing___uk",
    path: signingOEYeC0rwxdMeta?.path ?? "/signup/questionnaire/signing",
    meta: signingOEYeC0rwxdMeta || {},
    alias: signingOEYeC0rwxdMeta?.alias || [],
    redirect: signingOEYeC0rwxdMeta?.redirect,
    component: () => import("/opt/app/pages/signup/questionnaire/signing.vue").then(m => m.default || m)
  },
  {
    name: component_45stubzIDddqOzp9Meta?.name ?? undefined,
    path: component_45stubzIDddqOzp9Meta?.path ?? "/ocean",
    meta: component_45stubzIDddqOzp9Meta || {},
    alias: component_45stubzIDddqOzp9Meta?.alias || [],
    redirect: component_45stubzIDddqOzp9Meta?.redirect,
    component: component_45stubzIDddqOzp9
  },
  {
    name: component_45stubzIDddqOzp9Meta?.name ?? undefined,
    path: component_45stubzIDddqOzp9Meta?.path ?? "/supermarket",
    meta: component_45stubzIDddqOzp9Meta || {},
    alias: component_45stubzIDddqOzp9Meta?.alias || [],
    redirect: component_45stubzIDddqOzp9Meta?.redirect,
    component: component_45stubzIDddqOzp9
  },
  {
    name: component_45stubzIDddqOzp9Meta?.name ?? undefined,
    path: component_45stubzIDddqOzp9Meta?.path ?? "/inzhurbud",
    meta: component_45stubzIDddqOzp9Meta || {},
    alias: component_45stubzIDddqOzp9Meta?.alias || [],
    redirect: component_45stubzIDddqOzp9Meta?.redirect,
    component: component_45stubzIDddqOzp9
  },
  {
    name: component_45stubzIDddqOzp9Meta?.name ?? undefined,
    path: component_45stubzIDddqOzp9Meta?.path ?? "/fund-1001",
    meta: component_45stubzIDddqOzp9Meta || {},
    alias: component_45stubzIDddqOzp9Meta?.alias || [],
    redirect: component_45stubzIDddqOzp9Meta?.redirect,
    component: component_45stubzIDddqOzp9
  },
  {
    name: component_45stubzIDddqOzp9Meta?.name ?? undefined,
    path: component_45stubzIDddqOzp9Meta?.path ?? "/fund-2001",
    meta: component_45stubzIDddqOzp9Meta || {},
    alias: component_45stubzIDddqOzp9Meta?.alias || [],
    redirect: component_45stubzIDddqOzp9Meta?.redirect,
    component: component_45stubzIDddqOzp9
  },
  {
    name: component_45stubzIDddqOzp9Meta?.name ?? undefined,
    path: component_45stubzIDddqOzp9Meta?.path ?? "/sitemap.xml",
    meta: component_45stubzIDddqOzp9Meta || {},
    alias: component_45stubzIDddqOzp9Meta?.alias || [],
    redirect: component_45stubzIDddqOzp9Meta?.redirect,
    component: component_45stubzIDddqOzp9
  }
]